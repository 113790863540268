import {
    ADD_INVOICE_DETAIL_BUY_GOODS_AND_SERVICES,
    UPDATE_INVOICE_DETAIL_BUY_GOODS_AND_SERVICES,
    SET_ITBIS_TAX_BUY_GOODS_AND_SERVICES,
    DELETE_INVOICE_DETAIL_BUY_GOODS_AND_SERVICES,
    RESET_BUY_GOODS_AND_SERVICES,
    SET_DYNAMIC_TAX_INPUT_BUY_GOODS_AND_SERVICES,
    SET_DYNAMIC_INPUTS_VALUE_BUY_GOODS_AND_SERVICES,
    ADD_ROW_DETAIL_REPLENISHMENT_EXPENSES,
    GET_ALL_REPLENISHMENT_EXPENSES,
    SELECT_ROW_REPLENISHMENT_EXPENSES,
    FILTER_DATA_BY_ORIGIN_REPLENISHMENT_EXPENSES,
    FILTER_DATA_BY_TYPE_REPLENISHMENT_EXPENSES
} from '../../../../constants/ActionTypes'
// import { currencyFormat } from '../../../../util/DaFrameword'
import { ID } from '../../../../constants/Config'
import moment from 'moment'
const InitialState = {
    TableData: [],
    UltimateTableData: [],
    percentItbis: null,
    IdCurrentRow: ID(),
    DynamicTaxInputs:[],
    DynamicInputValue:[],
    Origin:null,
    Type :null
}
function ReplenishmentExpenses(state = InitialState, action) {
    switch (action.type) {
        case ADD_ROW_DETAIL_REPLENISHMENT_EXPENSES:
            const NewRow = action.payload;
            return {
                ...state,
                TableData: [...state.TableData, NewRow],
                IdCurrentRow: ID()
            }


        case GET_ALL_REPLENISHMENT_EXPENSES:

        const fulldata = action.payload.map(x=>({
            ...x,
            BoxAccountDisplay: x.boxCatalogAccount == 0  ? "Proveedor" :   x.boxAccountDisplay,
            ProviderName: x.providerDisplay,
            Rnc:x.rnc,
            RncDescription:x.rncDescription,
            NCF:x.ncf,
            Date: moment(x.date),
            DateDisplay: moment(x.date).format("DD/MM/YYYY"),
            ClassificationDisplay: x.classificationDisplay,
            Details:x.details,
            TypeDisplay: "Servicio",
            Value:x.value,
            itbis: x.itbis,
            legaltips: x.legaltips,
            isc:x.isc,
            others:x.others,
            Total: x.total,
            TypeDisplay: (x.type == 2 ? "Servicio" : "Bienes"),
            InternalSequence: `${x.abbreviationCode}-${x.number}`
        }))
            return   {
                ...state,
                selection: false,
                TableData: fulldata ,
                UltimateTableData: fulldata
            }
        // case UPDATE_INVOICE_DETAIL_BUY_GOODS_AND_SERVICES:
        //     const UpdateRow = action.payload;
        //     return {
        //         ...state,
        //         TableData: state.TableData.map(x => x.id === UpdateRow.id ? UpdateRow : x)
        //     }
        // case DELETE_INVOICE_DETAIL_BUY_GOODS_AND_SERVICES:
        //     const { productId } = action.payload;
        //     return {
        //         ...state,
        //         TableData: state.TableData.filter(x => x.productId !== productId)
        //     }
        case SET_ITBIS_TAX_BUY_GOODS_AND_SERVICES:
            return {
                ...state,
                percentItbis: action.payload.percentItbis * 0.01
            }

        // case RESET_BUY_GOODS_AND_SERVICES:
        //     return {
        //         ...state,
        //         TableData: [],
        //         IdCurrentRow: ID(),
        //         DynamicInputValue:[]
        //     }
        case SET_DYNAMIC_TAX_INPUT_BUY_GOODS_AND_SERVICES:
            return {
                ...state,
                DynamicTaxInputs:action.payload
            }
        case SET_DYNAMIC_INPUTS_VALUE_BUY_GOODS_AND_SERVICES:
            return{
                ...state,
                DynamicInputValue:action.payload
            }
        case SELECT_ROW_REPLENISHMENT_EXPENSES:
          var Index = state.TableData.findIndex(x=>x.masterHeaderId ==action.payload.masterHeaderId );
          let  ListData = state.TableData;
          ListData[Index].selection = !ListData[Index].selection;
            return{
                ...state,
                TableData: ListData

            }
        case FILTER_DATA_BY_TYPE_REPLENISHMENT_EXPENSES:
            const FilterTypeData = state.UltimateTableData.filter( x=> 
                                                                    ((x.ncf == "" && action.payload == 2) || (x.ncf !=="" && action.payload == 1 ) || action.payload == null) 
                                                                    && (x.boxCatalogAccount == state.Origin || state.Origin == null)  )

            return {
                ...state,
                TableData: FilterTypeData,
                Type: action.payload
                
            }
        case FILTER_DATA_BY_ORIGIN_REPLENISHMENT_EXPENSES:

        const FilterOriginData = state.UltimateTableData.filter( x=> 
                                                                    (x.boxCatalogAccount == action.payload || action.payload == null) 
                                                                        && ((x.ncf == "" && state.Type == 2) || (x.ncf !=="" && state.Type == 1 ) || state.Type == null))

            return {
                ...state,
                Origin: action.payload,
                TableData: FilterOriginData
            }
        default:
            return state;
    }
}

export default ReplenishmentExpenses;