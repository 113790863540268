import { RESET_INVENTORY_MOVEMENT_REVERSE_ROW_TABLE,Add_INVENTORY_MOVEMENT_REVERSE_ROW_TABLE} from '../../../../constants/ActionTypes'
import {zeroPad,currencyFormat} from '../../../../util/DaFrameword'

const InitialValue={
   InventoryMovementList:[]
}
function ReverseInventoryMovement(state=InitialValue,action){

    switch (action.type) {
        case Add_INVENTORY_MOVEMENT_REVERSE_ROW_TABLE:
            return {
                ...state,
               InventoryMovementList:action.payload.data.map(x=>({
                   productCode:zeroPad(x.inventoryId,8),
                   
                   description:x.inventory.name,

                   amount:x.amount,

                   price: currencyFormat(x.price),

                   value:currencyFormat(x.value)
                }))  
            }
         case RESET_INVENTORY_MOVEMENT_REVERSE_ROW_TABLE:
            return {
               InventoryMovementList:[]
            }
        default:
           return state
    }  

}

export default ReverseInventoryMovement;