import React from 'react'
import {Switch,Route} from 'react-router-dom'
import asyncComponent from "../../../util/asyncComponent";
const ConsultPointOfSale =({match})=>(
    <Switch>
          <Route  path={`${match.url}/EntryAndExitMovementByItem`} component={asyncComponent(()=>import( './EntryAndExitMovementByItem/index'))} />
          <Route  path={`${match.url}/InvoiceCopy`} component={asyncComponent(()=>import( './InvoiceCopy/index'))} />
    ``          <Route  path={`${match.url}/MakeReturnProductCopy`} component={asyncComponent(()=>import( './MakeReturnProductCopy/index'))} />
          
    </Switch>
)
export default ConsultPointOfSale;