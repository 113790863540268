import React from 'react'
import {Switch,Route} from 'react-router-dom'
import asyncComponent from "../../../util/asyncComponent";
const EntryPointOfSale =({match})=>(
    <Switch>
          <Route  path={`${match.url}/AccountingDepartment`} component={asyncComponent(()=>import( './AccountingDepartment/index'))} />
          <Route  path={`${match.url}/Inventory`} component={asyncComponent(()=>import( './Inventory/index'))} />
          <Route  path={`${match.url}/LocalPurchase`} component={asyncComponent(()=>import( './LocalPurchase/index'))} />
          <Route  path={`${match.url}/InventoryMovement`} component={asyncComponent(()=>import( './InventoryMovement/index'))} />
          <Route  path={`${match.url}/Invoice`} component={asyncComponent(()=>import( './Invoice/index'))} />
          <Route  path={`${match.url}/CheckOut`} component={asyncComponent(()=>import( './CheckOut/index'))} />
          <Route  path={`${match.url}/MakeReturnProduct`} component={asyncComponent(()=>import( './MakeReturnProduct/index'))} />
                  
          

    </Switch>
)
export default EntryPointOfSale;