import {DELETE_PRODUCT_DETAIL_PRODUCT_REGISTER,RESET_PRODUCT_ROW_TABLE,Add_PRODUCT_ROW_TABLE,UPDATE_PRODUCT_ROW_MOVEMENTS_TABLE} from '../../../../constants/ActionTypes'
import { zeroPad } from '../../../../util/DaFrameword'

const InitialState = {
   InvoiceDetails:[]
}


function MakeReturn(state = InitialState, action) {
    switch (action.type) {
        case Add_PRODUCT_ROW_TABLE:      
        return {
            ...state,
            InvoiceDetails:action.payload.data.map(x=>({
                
                productId:x.id,
                
                productCode:zeroPad(x.code,8),

                description:x.description,
                
                amount: x.quantity,
                
                value: x.price,
                
                netValue: x.value,
                
                discount: x.discount,
                
                ITBIS: x.itbisValue,
                
                totalValue: x.total,

                originalAmount:x.quantity
            })) 
        }
        
        case RESET_PRODUCT_ROW_TABLE:
            return {
                ...state,
                InvoiceDetails:[]
            }
            
        case DELETE_PRODUCT_DETAIL_PRODUCT_REGISTER:
            const productCode  = action.payload;
            return {
                ...state,
                InvoiceDetails: state.InvoiceDetails.filter(x => x.productCode !== productCode)
            }
        
        case UPDATE_PRODUCT_ROW_MOVEMENTS_TABLE:
        let NewData=state.InvoiceDetails.map((data, index)=>(data.productCode != action.payload.index ?  data : ({
            ...data,
            ...action.payload.data
          })));
          debugger
          return {
              ...state,
              InvoiceDetails:NewData
         }
        default:
           return state
                }
                
            }
            
export default MakeReturn; 
