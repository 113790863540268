import {DISABLE_HEADER_CREDIT_NOTICE,SET_PENDING_MOVEMENT_CREDIT_NOTICE,SET_VALUE_TO_DISTRIBUTE_CREDIT_NOTICE,UPDATE_TABLE_DATA_CREDIT_NOTICE,UPDATE_VALUE_TO_DISTRIBUTE_CREDIT_NOTICE,RESET_CREDIT_NOTICE,PENDING_BALANCE_CREDIT_NOTICE} from '../../../../constants/ActionTypes'
import moment from 'moment'
import {zeroPad,currencyFormat} from '../../../../util/DaFrameword'
const InitialState={
    DisabledHeader:false,
    PendingMovement:[],
    DistributeValue:0,
    UltimateDistributeValue:0,
    PendingBalance:0,
    AutomaticRetained:false,
    PercentRetained :undefined
}

function CreditNotice(state=InitialState, action){

        switch (action.type) {

            case DISABLE_HEADER_CREDIT_NOTICE:
                return {
                    ...state,
                    DisabledHeader:true
                }
            case SET_PENDING_MOVEMENT_CREDIT_NOTICE:
                return {
                    ...state,
                    PendingMovement:action.payload.map(x=>({
                        ...x, 
                        DateDisplay:moment(x.date).format("DD/MM/YYYY"),
                        TypeDisplay:`${x.abbreviationCode}`,
                        DocumentDisplay:zeroPad(x.number,x.length),
                        PendingValueDisplay:currencyFormat(x.pendingDebit),
                        Selected:false,
                        //RetainedValue:0,
                        ISRRetainedValue:0,
                        ITBISRetainedValue:0,
                        Concept:""
                    }))
                }
            case SET_VALUE_TO_DISTRIBUTE_CREDIT_NOTICE:
                return {
                    ...state,
                    DistributeValue:action.payload,
                    UltimateDistributeValue:action.payload,
                }
            case UPDATE_TABLE_DATA_CREDIT_NOTICE:
                const Row=action.payload;
                return {
                    ...state,
                    PendingMovement:state.PendingMovement.map(x=>(x.cxcTransactionHeaderId===Row.cxcTransactionHeaderId)?Row:x
                )
                }
            case UPDATE_VALUE_TO_DISTRIBUTE_CREDIT_NOTICE:
                const RetainedValue=state.PendingMovement.reduce((prev,curr)=> prev+(curr.ISRRetainedValue+curr.ITBISRetainedValue),0);
                return {
                    ...state,
                    DistributeValue:(state.UltimateDistributeValue- RetainedValue)
                }
            
            case PENDING_BALANCE_CREDIT_NOTICE:
                return {
                    ...state,
                    PendingBalance:action.payload
                }
            case RESET_CREDIT_NOTICE:
            return  InitialState
        
            // case SET_RETAINED_PERCENT_AND_STATUS_CREDIT_NOTICE:
                
            //     console.log()

            // return {
            //     ...state,
            //     AutomaticRetained: action.payload.IsAutomatic,
            //     PercentRetained  : action.payload.RetencionPercent
            // }
        
            default:
                return state;      
      }
}

export default CreditNotice;
